<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar>
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</top-bar>
		<div class="project-box">
			<div class="bg">
				<div class="title">专利&奖项</div>
				<div class="tb">Patents & Awards</div>
			</div>
		</div>
		<div class="t-box">
			<div class="c-box">
				Patents & Awards
				<div class="c-ti">专利&奖项</div>
			</div>
		</div>
<!-- 		<div class="new-box">
			<div class="c-box">
				<div class="new" v-for="i in 8" :style="i % 3 == 0 ? {} : {marginRight:27+'px'}">
					<div class="img-box">
						<img src="../static/home/banner2.png" class="img">
					</div>
					<div class="tr">专利奖项标题</div>
					<div class="cr">技术发明奖授予在运用科学技术知识做出产品、工艺、材料及其系统等重要技术发明的个人和单位。</div>
				</div>
			</div>
		</div> -->
		<div style="width: 100%;margin-top: 259px;">
			<footer-bar></footer-bar>
		</div>
	</div>
</template>

<script>
	import topBar from '@/components/header-bar.vue'
	import utils from '@/utils/common.js'
	import footerBar from '@/components/footer-bar.vue'
	export default{
		components:{
			topBar,
			footerBar
		},
		data(){
			return{
				name:'Prize',
				menus:utils.menus,
				active:'Prize',
				swiperIndex:0,
				hoverIndex:null
			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 3){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 4){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 6){
					return require('../static/home/logo2.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if(item.path != this.name && item.path != 'solution'){
					this.$router.push({
						name:item.path
					})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.wrap {
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
	    // background: #eeeeee;
		margin: 0;
		padding: 0;
		
	}
		.active-h{
			font-weight: 600;
		}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background: #007AFF;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-right: 197px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.project-box{
		width: 100%;
		height: 400px;
		// background: rgba(0, 0, 0, 0.5);
		background-image: url(../static/project/zhuanli.png);
		position: relative;
		background-size: 100% 100%;

		.bg{
			padding-top: 182px;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.6);
			.title{
				font-size: 48px;
				color: #FFFFFF;
				margin-left: 175px;
			}
			.tb{
				font-size: 28px;
				font-weight: 400;
				color: #CCCCCC;
				margin-top: 12px;
				margin-left: 175px;
			}
		}

	}
	
	.t-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		margin-bottom: 80px;
		.c-box{
			width: 550px;
			height: 58px;
			font-size: 58px;
			font-weight: bold;
			color: #F7F7F7;
			position: relative;
			.c-ti{
				font-size: 32px;
				font-weight: 600;
				color: #1A1A1A;
				position: absolute;
				bottom: 0;
				left: 35%;
			}
		}
	}
	
	.new-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 60px;
		.c-box{
			width: 1087px;
			display: flex;
			flex-wrap: wrap;
			// background: #000;
			.new{
				width: 344px;
				height: 431px;
				background: #FFFFFF;
				box-shadow: 2px 2px 13px 5px rgba(0, 0, 0, 0.04);
				margin-bottom: 28px;
				text-align: center;
				position: relative;
				.img-box{
					width: 100%;
					height: 261px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-bottom: 1px solid #f2f2f2;
					.img{
						width: 323px;
						height: 213px;
					}
				}
				.tr{
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					font-size: 18px;
					font-weight: 500;
					color: #1A1A1A;
					margin-top: 20px;
				}
				.cr{
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-top: 20px;
				}
				.bg-info{
					background: rgba(0, 0, 0, 0.4);
					position: absolute;
					left: 0;
					right: 0;
					top:0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 28px;
					font-weight: 500;
					color: #FFFFFF;
					padding-top: 180px;
					box-sizing: border-box;
				}
			}
		}
	}
</style>
